// import $ from 'jquery';
import 'floatthead';

export function countChecked()
{
    const checkAllBtnExists = $('.bulk_action input#check-all');
    let itemName = `bulkActionIds${window.location.pathname}`;
    if (window.location.href.indexOf('multipleSelect') > 0) {
        itemName = window.location.href;
    }

    if (checkState === 'all') {
        $('.bulk_action .bulk-check input').iCheck('check');
    }
    if (checkState === 'none') {
        $('.bulk_action .bulk-check input').iCheck('uncheck');
    }
    const saveItem = localStorage.getItem(itemName);
    let values = [];

    if (saveItem !== null) {
        values = JSON.parse(`[${saveItem}]`);
    }
    $('.bulk_action .bulk-check input').each(function bulkActionEach(e) {
        let index;
        let value;
        if ($(this).attr('id') !== undefined) {
            value = parseInt($(this).attr('id'));
            index = values.indexOf(value);
        } else if ($(this).attr('entityid') !== undefined) {
            value = parseInt($(this).attr('entityid'));
            index = values.indexOf(value);
        }
        if (this.checked === true) {
            if (index === -1) {
                values.push(value);
            }
        } else if (index !== -1) {
            values.splice(index, 1);
        }
    });
    localStorage.setItem(itemName, values);

    const checkCount = values.length;
    const colCount = $('.column-title').length;

    let form = checkAllBtnExists.closest('form')[0];
    if (checkCount) {
        $(form).find('.defaultExportIcons a').hide();
        $(form).find('.bulk-actions').attr('colspan', colCount).show();
        $(form).find('.bulk-actions-after').show();
        $(form).find('.action-cnt').html(checkCount);
    } else {
        $(form).find('.defaultExportIcons a').show();
        $(form).find('.bulk-actions').hide();
        $(form).find('.bulk-actions-after').hide();
    }
}

export function initCheckboxesBulkActions()
{
    const checkAllBtnExists = $('.bulk_action input#check-all');
    let values = [];
    if (checkAllBtnExists.length !== 0) {
        let itemName = `bulkActionIds${window.location.pathname}`;
        if (window.location.href.indexOf('multipleSelect') > 0) {
            itemName = window.location.href;
        }
        if (localStorage.getItem('selectEntitiesOpen') === 1) {
            localStorage.setItem('selectEntitiesOpen', 0);
            const selections = JSON.parse(localStorage.getItem(`currentSelections${top.location.pathname}`));
            const ids = [];
            for (const property in selections) {
                ids.push(property);
            }
            localStorage.setItem(itemName, ids);
        } else {
            localStorage.setItem(`currentSelections${top.location.pathname}`, JSON.stringify({}));
        }
        const saveItem = localStorage.getItem(itemName);
        if (saveItem !== null) {
            values = JSON.parse(`[${saveItem}]`);
        }
    }

    $("input[type='checkbox']")
        .not('.entityItemInput, .icheckbox_flat-green input, .leaflet-control-layers-selector, .appleSwitch, .noCheck')
        .iCheck({checkboxClass: 'icheckbox_flat-green'});

    $("input[type='radio']")
        .not('.leaflet-control-layers-selector, .iradio_flat-green input, .noCheck')
        .iCheck({radioClass: 'iradio_flat-green'});

    // pro dlazdice zarovnani na stred radku pro icheckbox
    $('.tiles-bulk-checkbox .icheckbox_flat-green').css('top', 'unset');

    if (checkAllBtnExists.length !== 0) {
        for (let i = 0; i < values.length; i++) {
            $(`.checkboxRows#${values[i]}`).iCheck('check');
            $(`.checkboxRows#${values[i]}`).parent().parent().parent()
                .addClass('selected');
            $(`.checkboxRows#${values[i]}`).parent().addClass('checked');
        }

        const checkCount = values.length;
        const colCount = $('.column-title').length;

        let form = checkAllBtnExists.closest('form')[0];
        if (checkCount) {
            $(form).find('.defaultExportIcons a').hide();
            $(form).find('.bulk-actions').attr('colspan', colCount).show();
            $(form).find('.bulk-actions-after').show();
            $(form).find('.action-cnt').html(checkCount);
        } else {
            $(form).find('.defaultExportIcons a').show();
            $(form).find('.bulk-actions').hide();
            $(form).find('.bulk-actions-after').hide();
        }
        initCheckboxesHandlers();
    }
}
window.initCheckboxesBulkActions = initCheckboxesBulkActions;

export function resetCheckboxesBulkActions()
{
    let itemName = `bulkActionIds${window.location.pathname}`;
    if (window.location.href.indexOf('multipleSelect') > 0) {
        itemName = window.location.href;
    }

    localStorage.setItem(itemName, []);
    const selections = JSON.parse(localStorage.getItem(`currentSelections${top.location.pathname}`));
    if (Object.keys(selections).length !== 0) {
        localStorage.setItem(localStorage.getItem('selectEntitiesName'), JSON.stringify({}));
        for (const property in selections) {
            parent.removeSelectedEntity(property);
        }
        localStorage.setItem(`currentSelections${top.location.pathname}`, JSON.stringify({}));
    }
    $("input[type='checkbox']").not('.leaflet-control-layers-selector').iCheck('uncheck');
    initCheckboxesBulkActions();
}
window.resetCheckboxesBulkActions = resetCheckboxesBulkActions;

export function initCheckboxesHandlers()
{
    const checkAllBtnExists = $('.bulk_action input#check-all');

    // Table without bulk_action
    if (checkAllBtnExists.length !== 0) {
        $('table input').on('ifChecked', function ifChecked() {
            checkState = '';
            $(this).parent().parent().parent()
                .addClass('selected');
            countChecked();
        });
        $('table input').on('ifUnchecked', function ifUnchecked() {
            checkState = '';
            $(this).parent().parent().parent()
                .removeClass('selected');
            countChecked();
        });
        $('.bulk_action input').on('ifChecked', function ifChecked2() {
            checkState = '';
            $(this).parent().parent().parent()
                .addClass('selected');
            countChecked();
        });
        $('.bulk_action input').on('ifUnchecked', function ifUnchecked2() {
            checkState = '';
            $(this).parent().parent().parent()
                .removeClass('selected');
            countChecked();
        });
        $('.bulk_action input').on('ifChecked', function ifChecked3() {
            checkState = '';
            $(this).parent().addClass('checked');
            countChecked();
        });
        $('.bulk_action input').on('ifUnchecked', function ifUnchecked3() {
            checkState = '';
            $(this).parent().removeClass('checked');
            countChecked();
        });
        $('.bulk_action input#check-all').on('ifChecked', () => {
            checkState = 'all';
            countChecked();
        });
        $('.bulk_action input#check-all').on('ifUnchecked', () => {
            checkState = 'none';
            countChecked();
        });
    }
}
window.initCheckboxesHandlers = initCheckboxesHandlers;

export function exportSelectedRows(elem, paramPrefix)
{
    let itemName = `bulkActionIds${window.location.pathname}`;
    if (window.location.href.indexOf('multipleSelect') > 0) {
        itemName = window.location.href;
    }
    const saveItem = localStorage.getItem(itemName);
    let values = [];
    if (saveItem !== null) {
        values = JSON.parse(`[${saveItem}]`);
    }
    const allowedIds = {};
    allowedIds[`${paramPrefix}allowedIds`] = values;

    let split = elem.href.split(`&${paramPrefix}allowedIds`);
    elem.href = split[0] + `&${$.param(allowedIds)}`;
    return true;
}
window.exportSelectedRows = exportSelectedRows;

export function initCalendars()
{
    const lang = $('html').attr('lang');
    $.datepicker.setDefaults($.datepicker.regional[lang]);
    $.timepicker.setDefaults($.timepicker.regional[lang]);

    $('.datepicker:not(.entityItemsTable tr.hidden input.datepicker, [readonly])').each(function datepickerEach() {
        $(this).datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            showWeek: true,
            firstDay: 1,
            changeMonth: true,
            changeYear: true,
            dateFormat: $(this).attr('jsdateformat')
        });

        if ($(this).attr('minDate')) {
            $(this).datepicker('option', 'minDate', new Date($(this).attr('minDate') * 1000));
        }

        if ($(this).attr('maxDate')) {
            $(this).datepicker('option', 'maxDate', new Date($(this).attr('maxDate') * 1000));
        }
    });

    $('.timepicker:not(.entityItemsTable tr.hidden input.datepicker, [readonly])').each(function timepickerEach() {
        $(this).timepicker({
            timeInput: true,
            timeFormat: $(this).attr('jstimeformat')
        });
    });

    $('.datetimepicker:not(.entityItemsTable tr.hidden input.datepicker, [readonly])').each(function datetimepickerEach() {
        $(this).datetimepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            showWeek: true,
            showDay: false,
            firstDay: 1,
            changeMonth: true,
            changeYear: true,
            timeInput: true,
            dateFormat: $(this).attr('jsdateformat'),
            timeFormat: $(this).attr('jstimeformat')
        });

        if ($(this).attr('minDate')) {
            $(this).datepicker('option', 'minDate', new Date($(this).attr('minDate') * 1000));
        }

        if ($(this).attr('maxDate')) {
            $(this).datepicker('option', 'maxDate', new Date($(this).attr('maxDate') * 1000));
        }
    });


    function getDate(element) {
        let date;
        try {
            date = $.datepicker.parseDate($(element).attr('dateformat'), element.value);
        } catch (error) {
            console.debug(element);
            console.debug(error)
            date = null;
        }

        return date;
    }

    $(".dateRangePicker:not(.entityItemsTable tr.hidden input.datepicker, [readonly]):not(.dateRangePickerInitialized)").each(
        function () {
            $(this).datepicker({
                showOtherMonths: true,
                selectOtherMonths: true,
                showWeek: true,
                firstDay: 1,
                changeMonth: true,
                changeYear: true,
                dateFormat: $(this).attr('dateformat'),
            }).addClass('dateRangePickerInitialized');

            if (this.id.endsWith('-from')) {
                let $to = $("#" + this.id.substring(0, this.id.length - 5) + '-to');
                if ($to.length) {
                    // set maxDate as value in "to" datepicker
                    $(this).datepicker("option", "maxDate", getDate($to.get(0)));
                    $(this).on('change', function () {
                        // when changed, limit minDate in "to" datepicker
                        $to.datepicker("option", "minDate", getDate(this));
                    })
                }
            } else if (this.id.endsWith('-to')) {
                let $from = $("#" + this.id.substring(0, this.id.length - 3) + '-from');
                if ($from.length) {
                    // set minDate as value in "from" datepicker
                    $(this).datepicker("option", "minDate", getDate($from.get(0)));
                    $(this).on('change', function () {
                        // when changed, limit maxDate in "from" datepicker
                        $from.datepicker("option", "maxDate", getDate(this));
                    })
                }
            }
       }
    );
}
window.initCalendars = initCalendars;

export function initTooltips() {
    let tooltips = $('[data-toggle = "tooltip"]:not(.tooltipActivated)');
    tooltips.filter(':not(.row-actions [data-toggle="tooltip"], thead [data-toggle="tooltip"])').tooltip({
        container: 'body',
        /* work around https://bugs.jqueryui.com/ticket/10689 */
        create: function() {
            $(this).data("ui-tooltip").liveRegion.remove();
        }
    });
    tooltips.filter('.row-actions [data-toggle="tooltip"], thead [data-toggle="tooltip"]').tooltip({
        position: {
            collision: 'fit flip',
            within: $('.x_content')
        },
        /* work around https://bugs.jqueryui.com/ticket/10689 */
        create: function() {
            $(this).data("ui-tooltip").liveRegion.remove();
        }
    });
    tooltips.addClass('tooltipActivated');

    $('[data-toggle="bsTooltip"]:not(.tooltipActivated)').bsTooltip({
        container: 'body',
        placement: 'auto'
    }).addClass('tooltipActivated');
}
window.initTooltips = initTooltips;

export function initSelect2Class() {
    $(document).ready(function documentReady() {
        $(document).find('select').each(function selectEach() {
            if ($(this).parent().hasClass('has-feedback')) {
                if ($(this).hasClass('has-feedback-left')) {
                    $(this).parent().find('.select2-selection__rendered').each(function selectionrenderedEach() {
                        $(this).addClass('iconPaddingLeft');
                    });
                }
            }
        });
    });
}
window.initSelect2Class = initSelect2Class;

export function initMultiSortable() {
    $(document).ready(function documentReady() {
        $('ul.sortable').multisortable();
        $('ul#availableOrders').sortable('option', 'connectWith', 'ul#selectedOrders');
        $('ul#selectedOrders').sortable('option', 'connectWith', 'ul#availableOrders');
    });
}
window.initMultiSortable = initMultiSortable;

function showEntity(link, width, height, scrolling)
{
    $.fancybox.open({
        toolbar: false,
        autoSize: false,
        src: link,
        type: 'iframe',
        iframe: {
            preload: true,
            css: {
                width: `${width}px`,
                height: `${height}px`,
            },
            attr: {
                scrolling,
            },
        },
        scrolling: 'no',
        keys: {
            close: [27],
        },
    });
}
window.showEntity = showEntity;

function showHelp(link)
{
    $.fancybox.open({
        toolbar: false,
        autoSize: false,
        src: link,
        type: 'iframe',
        iframe: {
            preload: true,
            css: {
                width: '100%',
                height: '100%',
            }
        },
        baseClass: 'fancyHelp',
        scrolling: 'no',
        keys: {
            close: [27],
        },
    });
}
window.showHelp = showHelp;

function showEntities(el, name, filter)
{
    if (filter === undefined) {
        filter = null;
    }
    entitiesForm = $(el).closest('form').attr('id');
    entitiesInput = $(el).closest('div.input-group').find('input[type=text]').attr('id');
    entitiesName = name;
    let value = $(el).parents().eq(2).find('input[type=text]').val();
    let link = `${$(el).attr('href')}&entitiesSelect=1&inputValueToFill=` + value;

    if (filter) {
        link += filter;
    }

    $.fancybox.open({
        src: link,
        type: 'iframe',
        width: '80%',
        modal: false,
        keys: {
            close: [27],
        },
    });
}
window.showEntities = showEntities;

// hide erase button from entitiesForm
function selfHide(el)
{
    $(el).addClass('disn');
}
window.selfHide = selfHide;

function showEntitiesFilter(el, name)
{
    entitiesForm = $(el).closest('form').attr('id');
    entitiesInput = $(el).closest('div.input-group').find('input[type=text]').attr('id');
    entitiesName = name;
    const link = `${$(el).attr('href')}&entitiesSelect=1`;
    $.fancybox.open({
        src: link,
        type: 'iframe',
        width: '80%',
        modal: false,
        keys: {
            close: [27],
        },
    });

    $(el).closest('div.input-group').find('.input-group-addon.danger').removeClass('disn');
}
window.showEntitiesFilter = showEntitiesFilter;

let entitiesForm = null;
let entitiesName = null;
let entitiesInput = null;
export let activeMultipleSelect = null;
window.activeMultipleSelect = activeMultipleSelect;

function showMultipleEntities(el, name)
{
    entitiesForm = $(el).closest('form');
    entitiesName = name;

    if (window.activeMultipleSelect == null) {
        localStorage.setItem(`currentSelections${top.location.pathname}`, JSON.stringify({}));
        localStorage.setItem(name, JSON.stringify({}));
    }
    window.activeMultipleSelect = name;

    localStorage.setItem(
        `currentSelections${top.location.pathname}`,
        localStorage.getItem(window.activeMultipleSelect),
    );
    localStorage.setItem('selectEntitiesOpen', 1);
    localStorage.setItem('selectEntitiesName', window.activeMultipleSelect);
    const link = `${$(el).attr('href')}&multipleSelect=1`;
    $.fancybox.open({
        src: link,
        type: 'iframe',
        width: '80%',
        modal: false,
        keys: {
            close: [27],
        },
        afterClose() {
            localStorage.setItem('selectEntitiesOpen', 0);
            localStorage.setItem('selectEntitiesName', JSON.stringify({}));
        },
    });
}
window.showMultipleEntities = showMultipleEntities;

function displayChosenEntity(id, name, additionalInfo)
{
    $(`form#${entitiesForm} input#${entitiesInput}`).val(name);
    $(`form#${entitiesForm} input#${entitiesInput}`)
        .closest('div.input-group').find('.input-group-addon.danger').removeClass('disn');
    $(`form#${entitiesForm} #${entitiesName}`).attr('value', id);

    if (additionalInfo != null) {
        $(`#${additionalInfo[0]}`).attr('value', additionalInfo[1]);
    }

    $(`#${entitiesName}`).change();
    Nette.validateControl($(`form#${entitiesForm} input#${entitiesInput}`)[0]);
}
window.displayChosenEntity = displayChosenEntity;

function chooseEntity(id, name, additionalInfo)
{
    parent.jQuery.fancybox.close();
    parent.displayChosenEntity(id, name, additionalInfo);
}
window.chooseEntity = chooseEntity;

export function saveSelectedEntity(id, name)
{
    const d = document.getElementById('dictionary');
    const dictionary = JSON.parse(d.textContent || d.innerHTML);
    const deleteButton = '<div style="float: right">'
        + `<a href="#" title="${dictionary.remove}" id="delete-entity-${window.activeMultipleSelect}-${id}"`
        +   ` data-id="${id}" data-entity-list="${window.activeMultipleSelect}">`
        + '<span class="fa fa-close"></span>'
        + '</a>'
        + '</div>';

    const entitiesList = JSON.parse(localStorage.getItem(window.activeMultipleSelect));
    entitiesList[id] = name;
    $(`#${window.activeMultipleSelect}JSON`).val(JSON.stringify(entitiesList));
    $(`#${window.activeMultipleSelect}`).DataTable().row.add([id, name + deleteButton]);
    $(`#${window.activeMultipleSelect}`).DataTable().draw();
    localStorage.setItem(window.activeMultipleSelect, JSON.stringify(entitiesList));
    localStorage.setItem(
        `currentSelections${top.location.pathname}`,
        localStorage.getItem(window.activeMultipleSelect),
    );
    $(`form:not(.ays-ignore) #${window.activeMultipleSelect}JSON`).trigger('checkform.areYouSure');
    $(`#delete-entity-${window.activeMultipleSelect}-${id}`).click((event) => {
        event.preventDefault();
        window.activeMultipleSelect = $(event.currentTarget).attr('data-entity-list');
        removeSelectedEntity($(event.currentTarget).attr('data-id'));
    });
}
window.saveSelectedEntity = saveSelectedEntity;

export function showOnlySelectedMultipleEntities(element, multipleSelectName)
{
    const entitiesList = JSON.parse(localStorage.getItem(multipleSelectName));
    let ajaxLink = $(element).data('ajax-link');
    $.nette.ajax({
        type: 'get',
        url: ajaxLink,
        data: {
            showIds: Object.keys(entitiesList),
            btnActive: $(element).data('active')
        }
    });
}
window.showOnlySelectedMultipleEntities = showOnlySelectedMultipleEntities;

export function removeSelectedEntity(id)
{
    const entitiesList = JSON.parse(localStorage.getItem(window.activeMultipleSelect));
    delete entitiesList[id];
    $(`#${window.activeMultipleSelect}`).DataTable()
        .row($(`#${window.activeMultipleSelect} td`).filter(function filterActiveMultple() {
            return $(this).text() === id;
        }).closest('tr')).remove();

    $(`#${window.activeMultipleSelect}`).DataTable().draw();
    $(`#${window.activeMultipleSelect}JSON`).val(JSON.stringify(entitiesList));
    localStorage.setItem(window.activeMultipleSelect, JSON.stringify(entitiesList));
    localStorage.setItem(
        `currentSelections${top.location.pathname}`,
        localStorage.getItem(window.activeMultipleSelect),
    );
    $(`form:not(.ays-ignore) #${window.activeMultipleSelect}JSON`).trigger('checkform.areYouSure');
}
window.removeSelectedEntity = removeSelectedEntity;

// Independently templates without require sign *
export function updateRequiredLabels()
{
    $('.required label').append('<span class="required"> *</span>');
}
window.updateRequiredLabels = updateRequiredLabels;

export function startTime()
{
    const today = new Date();
    const h = today.getHours();
    let m = today.getMinutes();
    let s = today.getSeconds();
    m = checkTime(m);
    s = checkTime(s);
    $('#actualTime').html(`${h}:${m}:${s}`);
    $('#topTime').html(`${h}:${m}`);
    const t = setTimeout(startTime, 500);
}
window.startTime = startTime;

function checkTime(i)
{
    // add zero in front of numbers < 10
    if (i < 10) {
        i = `0${i}`;

        return i;
    }
}

let countUpTimes = [];
export let countUpIds = [];

export function initCountUps()
{
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getYear() + 1900;
    const hour = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();

    countUpIds = [];
    countUpTimes = [];

    $('.countUp').each(function countUpEach() {
        const timeParts = $(this).html().split(':');
        countUpIds.push($(this).attr('id'));
        countUpTimes.push(
            mktime(year,
                month,
                day,
                hour - timeParts[0],
                minutes - timeParts[1],
                seconds - timeParts[2]),
        );
    });
    setTimeout(countUp, 500);
}
window.initCountUps = initCountUps;

function mktime(year, month, day, hour, minutes, seconds)
{
    return new Date(year, month - 1, day, hour, minutes, seconds);
}

function countUp()
{
    const now = new Date();
    for (let i = 0; i < countUpIds.length; i++) {
        const seconds = Math.floor((now.getTime() - countUpTimes[i].getTime()) / 1000);
        const time = secondsToHHMMSS(seconds);
        $(`#${countUpIds[i]}`).html(time);
    }
    setTimeout(countUp, 500);
}

function secondsToHHMMSS(sec_num)
{
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = `0${hours}`;
    }

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }

    if (seconds < 10) {
        seconds = `0${seconds}`;
    }

    return `${hours}:${minutes}:${seconds}`;
}

function timestampToHHMMSS(stamp)
{
    const date = new Date(stamp * 1000);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = `0${date.getMinutes()}`;
    // Seconds part from the timestamp
    const seconds = `0${date.getSeconds()}`;
    // Will display time in 10:30:23 format
    const formattedTime = `${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;

    return formattedTime;
}
window.timestampToHHMMSS = timestampToHHMMSS;

function ucfirst(string)
{
    return string[0].toUpperCase() + string.slice(1);
}

export function initEntitiesTypeaheads()
{
    $('input:text.entitiesForm').each(function entitiesFormEach() {
        const source = JSON.parse($(this).attr('typeahead'));
        const entitiesClosestForm = $(this).closest('form').attr('id');
        $(this).typeahead({
            source,
            autoSelect: true,
            showHintOnFocus: true,
            minLength: 0,
            hint: true,
            fitToElement: true,
            items: 50,
            val: '',
            afterSelect(item) {
                const entitiesHidden = $(`form#${entitiesClosestForm} input#${item.parent}`);
                const entitiesInput = entitiesHidden.siblings('div.input-group').find('input[type=text]').attr('id');
                entitiesHidden.val(item.id).trigger('change');
                Nette.validateControl($(`form#${entitiesClosestForm} input#${entitiesInput}`)[0]);
            },
        });
        // if value is set - show remove button
        if (this.value !== undefined && this.value !== '') {
            $(this).closest('div.input-group').find('.input-group-addon.danger').removeClass('disn');
        }
    });
}

export function initEntitiesTables()
{
    $('table.entitiesListTable:not(.dataTable)').each(function entitiesListEach() {
        const allowed = $(`#${$(this).attr('id')}Allowed`).val();
        const d = document.getElementById('dictionary');
        const dictionary = JSON.parse(d.textContent || d.innerHTML);

        localStorage.setItem($(this).attr('id'), {});

        if (allowed === 'true') {
            $(this).html(`<thead><tr><th>${dictionary.id}</th><th>${dictionary.name}</th></tr><thead><tbody></tbody>`);
        }

        $(this).prev().prev()
            .replaceWith(`<label class='control-label col-md-3 col-sm-3 col-xs-12'>${$(this).attr('label')}</label>`);
        $(this).prev().attr({ id: 'dependency-span', class: 'wrap' });
        $(this).prev().find('input').attr({ class: allowed === 'true' ? 'btn btn-info' : 'btn' });
        $(this).parents('div.form-group')
            .find('div.wrap, table.wrap').wrapAll("<div class='col-md-6 col-sm-6 col-xs-12' />");

        if (allowed === 'true') {
            $(this).DataTable({
                language: dataTablesLangs[$('html').attr('lang')],
                order: [[0, 'asc']],
                retrieve: true,
                pageLength: 5,
                paging: false,
                lengthChange: false,
                searching: false,
                ordering: false,
                info: false,
                autoWidth: false,
            });
        }

        const object = JSON.parse($(`#${$(this).attr('id')}JSON`).val());
        window.activeMultipleSelect = $(this).attr('id');
        localStorage.setItem(window.activeMultipleSelect, JSON.stringify({}));

        for (const property in object) {
            if (object.hasOwnProperty(property)) {
                saveSelectedEntity(property, object[property]);
            }
        }
    });
}
window.initEntitiesTables = initEntitiesTables;

// zprovozneni v rychlych filtrech
function dropdown()
{
    $('.dropdown-toggle button').attr('aria-expanded', 'true');
    $('.dropdown button').toggleClass('open');
}
window.dropdown = dropdown;

// filter
function showFilter(element)
{
    let formElement = '';
    if (element !== undefined) {
        const form = element.closest('form');
        const id = $(form).attr('id');
        formElement = 'form#' + id + ' ';
    }
    const hotKeysLink = $('#hotKeysLink');
    let hotKeysLinkValue = hotKeysLink.prop('href');
    if ($(formElement + '.filter-body').css('display') === 'none') {
        $(formElement + '.filter-body').fadeIn(() => {
            $(formElement + '.floatedTable').floatThead('reflow');
        });
        window.location.hash = '#filterOpen';
        if (hotKeysLinkValue) {
            hotKeysLinkValue = hotKeysLinkValue + '%23filterOpen';
        }
        if ($(formElement + '.ordering-body').css('display') !== 'none') {
            $(formElement + '.ordering-body').fadeOut(() => {
                $(formElement + '.floatedTable').floatThead('reflow');
            });
        }
    } else {
        $(formElement + '.filter-body').fadeOut(() => {
            $(formElement + ' .floatedTable').floatThead('reflow');
        });
        window.location.hash = '#filterClose';
        if (hotKeysLinkValue) {
            hotKeysLinkValue = hotKeysLinkValue.replace('%23filterOpen', '');
        }
    }
    if (hotKeysLinkValue) {
        hotKeysLink.prop('href', hotKeysLinkValue);
    }
    return false;
}
window.showFilter = showFilter;

// ordering
function showOrdering()
{
    if ($('.ordering-body').css('display') === 'none') {
        $('.ordering-body').fadeIn(() => {
            $('.floatedTable').floatThead('reflow');
        });
        if ($('.filter-body').css('display') !== 'none') {
            $('.filter-body').fadeOut(() => {
                $('.floatedTable').floatThead('reflow');
            });
        }
    } else {
        $('.ordering-body').fadeOut(() => {
            $('.floatedTable').floatThead('reflow');
        });
    }
}
window.showOrdering = showOrdering;

function reloadList(element, listType)
{
    selected = $(`#${element}`).find(':selected').val();
    $.nette.ajax({
        type: 'get',
        url: '?do=reloadSubtype',
        data: {
            listName: listType,
        },
        success(el) {
            $(`#${element}`).empty();
            for (const key in el) {
                if (typeof (el[key] === 'string')) {
                    if (key === selected) {
                        $(`#${element}`).append(`<option value="${key}" selected>${el[key]}</option>`);
                    } else {
                        $(`#${element}`).append(`<option value="${key}">${el[key]}</option>`);
                    }
                }
            }
        },
    });
}
window.reloadList = reloadList;

/**
 * @return { number }
 */
function GetIEVersion()
{
    const sAgent = window.navigator.userAgent;
    const Idx = sAgent.indexOf('MSIE');

    // If IE, return version number.
    if (Idx > 0) {
        return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)));
    }

    // If IE 11 then look for Updated user agent string.
    if (navigator.userAgent.match(/Trident\/7\./)) {
        return 11;
    }

    // Edge detection
    if (navigator.userAgent.match(/Edge\//)) {
        return 1000;
    }
    return 0; // It is not IE
}
window.GetIEVersion = GetIEVersion;

function slug(str)
{
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'ýěščřžáíéóůúãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
    const to = 'yescrzaieouuaaaaaeeeeeiiiiooooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}
window.slug = slug;

(function smartresizeCallback($, sr) {
    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    const debounce = function debounceFunction(func, threshold, execAsap) {
        let timeout;

        return function debounced()
        {
            const obj = this; const
                args = arguments;
            function delayed()
            {
                if (!execAsap) {
                    func.apply(obj, args);
                }
                timeout = null;
            }

            if (timeout) {
                clearTimeout(timeout);
            } else if (execAsap) {
                func.apply(obj, args);
            }

            timeout = setTimeout(delayed, threshold || 100);
        };
    };

    // smartresize
    jQuery.fn[sr] = function bindResize(fn) {
        return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
}(jQuery, 'smartresize'));

// fce zaridi korektni tabulaci v ramci formu (doplneno kvuli safari)
const Tab = {};
Tab.i = 1;
Tab.items = 0;

function fixTabulation()
{
    /* This can be used to auto-assign tab-indexes, or
    #  commented out if it manual tab-indexes have
    #  already been assigned.
    */
    $('input, select, textarea').each(function tabulationFixEach() {
        $(this).attr('tabindex', Tab.i);
        Tab.i++;
        Tab.items++;
    });

    Tab.i = 0;

    /* We need to listen for any forward or backward Tab
    #  key event tell the page where to focus next.
    */
    $(document).on({
        keydown(e) {
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                if (e.keyCode === 9 && !e.shiftKey) { // Tab key pressed
                    e.preventDefault();
                    Tab.i !== Tab.items ? Tab.i++ : Tab.i = 1;
                    $(`input[tabindex="${Tab.i}"], select[tabindex="${Tab.i}"], textarea[tabindex="${Tab.i}"]`)
                        .not('input[type="hidden"]').focus();
                }
                if (e.shiftKey && e.keyCode === 9) { // Tab key pressed
                    e.preventDefault();
                    Tab.i !== 1 ? Tab.i-- : Tab.i = Tab.items;
                    $(`input[tabindex="${Tab.i}"], select[tabindex="${Tab.i}"], textarea[tabindex="${Tab.i}"]`)
                        .not('input[type="hidden"]').focus();
                }
            }
        },
    });

    /* We need to update Tab.i if someone clicks into
    #  a different part of the form.  This allows us
    #  to keep tabbing from the newly clicked input
    */
    $('input[tabindex], select[tabindex], textarea[tabindex]').not('input[type="hidden"]')
        .focus(function tabIndexEach(e) {
            Tab.i = $(this).attr('tabindex');
        });
}
window.fixTabulation = fixTabulation;

export function haveArraysSameValues(array1, array2) {
    if (array1.length !== array2.length) {
        return false;
    }
    let sortedArray1 = array1.concat().sort();
    let sortedArray2 = array2.concat().sort();

    for (let i = 0; i < array1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) {
            return false;
        }
    }
    return true;
}

function showImageMap(url) {
    showHelp(url + '&showEntity=1');
};
window.showImageMap = showImageMap;
