import { haveArraysSameValues } from './main.functions';

function initHotKeys(keyCodes, hotKeyIds) {
    const pressedKeys = [];

    if (keyCodes.length > 0) {
        keyCodes = keyCodes.map(hotKeysString => JSON.parse(hotKeysString));

        document.addEventListener('keydown', (event) => {
            let keyIndex = pressedKeys.indexOf(event.code);
            if (keyIndex !== -1) {
                return;
            }
            pressedKeys.push(event.code);

            if (pressedKeys.length > 1) {
                keyCodes.forEach((hotKeys, index) => {
                    if (haveArraysSameValues(hotKeys, pressedKeys)) {
                        event.preventDefault();
                        document.getElementById(`myHotKey-id-${hotKeyIds[index]}`).click();
                    }
                });
            }
        });

        document.addEventListener('keyup', (event) => {
            let keyIndex = pressedKeys.indexOf(event.code);
            if (keyIndex !== -1) {
                pressedKeys.splice(keyIndex, 1);
            }
        });
    }
}
window.initHotKeys = initHotKeys;

const hotKeysEditor = {
    initFunction: null
};

hotKeysEditor.initFunction = () => {
    if ($('input[name="hasMenuSign"]').is(':checked') === false) {
        $('#menuSignInputGroup').hide();
    }

    $('label[for="frm-hotKeyForm-hasMenuSign"]').each((index, element) => {
        element.addEventListener('click', (event) => {
            if (event.target.tagName === 'INPUT') {
                return;
            }
            $('#menuSignInputGroup').toggle();
        }, true);
    });

    $('select[name="menuIcon"]').select2({
        escapeMarkup: markup => markup,
        templateResult: data => `<i class="${data.text}"></i> ${data.text}`,
        templateSelection: data => `<i class="${data.text}"></i>`
    });

    // Form with groups fix
    $('#frm-hotKeyForm input[name="_submit"]')
        .parent().removeClass('col text-center').addClass('col-md-6 col-sm-6 col-xs-12')
        .parent()
        .parent()
        .removeClass('col-md-12 col-sm-12 col-xs-12')
        .addClass('col-md-offset-3');

    const $hotKeysInput = $('input[name="keyCodes"]');
    const hotKeysDummyInput = document.createElement('div');
    hotKeysDummyInput.classList.add('dummy-input');

    let keyCodes = $hotKeysInput.val() === '' ? [] : JSON.parse($hotKeysInput.val());

    const removeKeyElementCallback = (keyCode) => {
        keyCodes = keyCodes.filter(value => value !== keyCode);
        $hotKeysInput.val(keyCodes.length > 0 ? JSON.stringify(keyCodes) : '');
    };

    // initialization of dummy input default values
    keyCodes.forEach((keyCode) => {
        hotKeysDummyInput.appendChild(createHotKeyElement(keyCode, removeKeyElementCallback));
    });

    // initialization of the input for hot key adding to selection
    hotKeysDummyInput.addEventListener('click', () => {
        let $modalElement = $('#addHotKeyModal');
        $modalElement.modal({ backdrop: 'static' }).modal('show');

        document.addEventListener('keydown', (event) => {
            $modalElement.modal('hide');

            if (keyCodes.includes(event.code) === false) {
                keyCodes.push(event.code);
                $hotKeysInput.val(JSON.stringify(keyCodes));
                hotKeysDummyInput.appendChild(createHotKeyElement(event.code, removeKeyElementCallback));
            }
        }, { once: true });
    });

    // inserting dummy input into form
    $hotKeysInput.parent().prepend(hotKeysDummyInput);

    function createHotKeyElement(keyCode, removeCallback) {
        const wrapper = document.createElement('div');
        wrapper.classList.add('hotkeyButton');

        const hotKey = document.createElement('a');
        hotKey.classList.add('btn', 'btn-info', 'disabled');
        hotKey.textContent = keyCode;

        const deleteButton = document.createElement('a');
        deleteButton.classList.add('btn', 'btn-danger', 'deleteButton');
        deleteButton.textContent = 'x';
        deleteButton.addEventListener('click', () => {
            removeCallback(keyCode);
            wrapper.parentNode.removeChild(wrapper);
        });
        wrapper.appendChild(hotKey);
        wrapper.appendChild(deleteButton);
        wrapper.addEventListener('click', (event) => {
            event.stopPropagation();
        });
        return wrapper;
    }
};

window.hotKeysEditor = hotKeysEditor;
