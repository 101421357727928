'use strict';

import '../css/gentelella.nais.css';
import '../css/darkroom.css';
import '../css/nais.style.css';

import 'floatthead';
import * as functions from './main.functions';
import './hotkeys';
import './entityItems';

let NProgress = require('accessible-nprogress');
let Promise = require('es6-promise');

window.Promise = require('es6-promise');
window.PNotify = require('pnotify');
window.Clipboard = require('clipboard');
window.moment = require('moment');
window.domtoimage = require('./vendor/dom-to-image');
window.jsPDF = require('./vendor/jspdf.min');

// Show the progress bar
if (typeof NProgress !== 'undefined') {
    document.addEventListener('DOMContentLoaded', () => {
        NProgress.start();
    }, true);
}

function getQueryParam(url, key) {
    let queryStartPos = url.indexOf('?');
    if (queryStartPos === -1) {
        return;
    }
    let params = url.substring(queryStartPos + 1).split('&');
    for (let i = 0; i < params.length; i++) {
        let pairs = params[i].split('=');
        if (decodeURIComponent(pairs.shift()) == key) {
            return decodeURIComponent(pairs.join('='));
        }
    }
}

$(document).ready(() => {
    // enable delete button after DOM load
    $('.delete').css('pointer-events', 'auto');
    // tabulace pro safari (a ostatni prohlizece)
    fixTabulation();
    PNotify.prototype.options.styling = 'bootstrap3';

    let choiceSelectId = getQueryParam(window.location.href, 'choiceSelectId');
    let choiceSelectName = getQueryParam(window.location.href, 'choiceSelectName');

    if (choiceSelectId && choiceSelectName) {
        chooseEntity(choiceSelectId, choiceSelectName.replaceAll('+', ' '));
    }

    // fill form input value
    let inputNameToFill = getQueryParam(window.location.href, 'inputNameToFill');
    if (inputNameToFill) {
        let inputValueToFill = getQueryParam(window.location.href, 'inputValueToFill');
        $("input[name=" + inputNameToFill + "]").val(inputValueToFill);
    }

    $('#sidebar-menu').find('a').on('click.toggleMenu', function onClickSidebar(ev) {
        let $li = $(this).parent();
        let $ul = $('ul:first', $li);

        function afterSlide($ul, $li, open) {
            if (open) {
                $li.addClass('menu-open');
            } else {
                $li.removeClass('menu-open');
            }
            $ul.css('display', '');
        }

        if ($li.is('.menu-open')) {
            $ul.slideUp(() => {
                afterSlide($ul, $li, false);
            });
        } else {
            $ul.slideDown(() => {
                afterSlide($ul, $li, true);
            });

            if ($('body').hasClass('nav-sm') && $li.parent().hasClass('side-menu')) {
                // close other open menus, if menu is small
                $li.siblings('.menu-open').find('> a').trigger('click.toggleMenu');
            }
        }
    });
    if ($('body').hasClass('nav-md')) {
        $('#menu_toggle').find('i').removeClass('fa fa-angle-double-left').addClass('fa fa-angle-double-left');
    } else {
        $('#menu_toggle').find('i').removeClass('fa fa-angle-double-left').addClass('fa fa-angle-double-right');
        $("#sidebar-menu").find('ul.side-menu > li.menu-open').removeClass('menu-open');
    }

    // toggle small or large menu
    $('#menu_toggle').on('click', () => {
        if ($('body').hasClass('nav-md')) {
            $("#sidebar-menu").find('ul.side-menu > li.active').removeClass('menu-open');
            $('#menu_toggle').find('i').removeClass('fa fa-angle-double-left').addClass('fa fa-angle-double-right');
            localStorage.setItem('menu_toggle', 'nav-sm');
        } else {
            $("#sidebar-menu").find('ul.side-menu > li.active').addClass('menu-open');
            $('#menu_toggle').find('i').removeClass('fa fa-angle-double-right').addClass('fa fa-angle-double-left');
            localStorage.setItem('menu_toggle', 'nav-md');
        }
        $('body').toggleClass('nav-md nav-sm');
        $("#sidebar-menu").find('li:not(.menu-open) > ul:visible').css('display',''); // let CSS handle display property
    });

    // enable delete button after DOM load
    $('.delete').css('pointer-events', 'auto');

    // hide context menus (rightclick) on ESC press
    $(document).keydown((e) => {
        let code = e.keyCode || e.which;
        if (code === 27) {
            $('.contextMenu').fadeOut(200);
        }
    });

    // focus on first input
    setTimeout(() => {
        // due to forms with tabs
        // eslint-disable-next-line max-len
        $('form.presenter-action:not(.modal form):not(.no-focus):not(.dateFormat):first *:input[type!=hidden]:not(.no-focus):not([readonly]):first').focus();
    }, 100);

    $(window).on('shown.bs.modal', () => {
        // for modal-dialogs
        $('.modal.in .modal-body *:input[type!=hidden]:not([readonly]):first').focus();
    });

    // hide history
    $(document).on('click', 'img.history', function imgHistoryClicked() {
        $('img.history').not(this).popover('hide');

        let el = $(this);
        $.nette.ajax({
            url: $(this).attr('url'),
            dataType: 'html',
            async: false,
            data: {
                entryId: $(this).attr('targetId'),
                entity: $(this).attr('targetEntity'),
                property: $(this).attr('targetColumn'),
            },
            success: (ret) => {
                el.popover({
                    placement: 'auto',
                    content: ret,
                    html: true,
                    trigger: 'manual',
                }).popover('show');
            },
        });
        return false;
    });

    let globalTimeout = null;
    // ulozeni jiz nalezenych hodnot
    let loadedValues = [];
    $('.hasPredictor').on('keyup', function keyUpHasPredictor(e) {
        // backspace ignoruji
        if (e.which === 8) {
            return;
        }
        let elem = $(this);
        let currentId = elem.attr('id');
        if ($(this).attr('readonly') === 'readonly') {
            return;
        }
        if (globalTimeout != null) {
            clearTimeout(globalTimeout);
        }
        let hasList = $(this).attr('list');
        if (typeof hasList === typeof undefined || hasList === false) {
            $(this).attr('list', `${$(this).attr('id')}-list`);
        }
        let searchedAttribute = $(this).attr('name');
        let searchQuery = $(this).val();
        let minLength = $(this).attr('data-min-length') || 3;
        let numberOfLines = $(this).attr('data-number-of-lines') || 10;
        let formName = $(this).attr('data-name');
        if (searchQuery.length < minLength) {
            return;
        }
        globalTimeout = setTimeout(() => {
            globalTimeout = null;
            $.nette.ajax({
                type: 'get',
                url: '?do=Predictor',
                data: {
                    attributeName: searchedAttribute,
                    searchQuery,
                    limit: numberOfLines,
                    formName,
                },
                complete: (result) => {
                    let list = document.getElementById(`${elem.attr('id')}-list`);
                    if (!list) {
                        list = document.createElement('datalist');
                        list.id = `${elem.attr('id')}-list`;
                    }
                    if (result.responseJSON && result.responseJSON.length > 0) {
                        numberOfLines = result.responseJSON.length;
                        for (let i = 0; i < numberOfLines; i++) {
                            // jestli hodnota v listu jiz je - nepridavam znova
                            if (loadedValues.indexOf(result.responseJSON[i].value) !== -1) {
                                continue;
                            }
                            let option = document.createElement('option');
                            option.value = result.responseJSON[i].value;
                            option.text = result.responseJSON[i].value;
                            loadedValues.push(result.responseJSON[i].value);
                            list.appendChild(option);
                        }
                        $(`#${currentId}`).append(list);
                        $(`#${currentId}`).css('display', 'block');
                        $(`#${currentId}`).removeClass('hasPredictor');
                    }
                },
            });
        }, 1000);
    });

    $('.delOnlyText').on('keypress', function keyPressDelOnlyText(event) {
        let input = $(this);
        let type = input.attr('typeahead');
        let substring = input.val().toLowerCase();

        // allow cursor keys, delete and backspace
        if (event.which !== 0 && event.which !== 8) {
            let jsonobj = JSON.parse(type);
            for (let i = 0; i < jsonobj.length; i++) {
                let string = jsonobj[i].name.toLowerCase();

                if (string.indexOf(substring) !== -1) {
                    break;
                } else if (i === (jsonobj.length) - 1) {
                    event.preventDefault();
                    return false;
                }
            }
        }
    });

    let requests = 0;
    $(document).ajaxStart(() => {
        if (requests === 0) {
            NProgress.start();
        }
        requests++;
    });

    $(document).ajaxStop(() => {
        requests--;
        if (requests === 0) {
            NProgress.done();
        }
    });

    functions.startTime();

    $(() => {
        $.nette.init();
    });

    // after ajax refresh important !!!
    $.nette.ext({
        load() {
            functions.initCalendars();
            functions.initCountUps();
            functions.initEntitiesTypeaheads();
            functions.initCheckboxesBulkActions();
            functions.initEntitiesTables();
            functions.initTooltips();
            functions.initSelect2Class();
            functions.initMultiSortable();

            $('form').submit(() => {
                if (typeof (CKEDITOR) !== 'undefined') {
                    for (let inst in CKEDITOR.instances) {
                        CKEDITOR.instances[inst].updateElement();
                    }
                }
            });

            $('#sortable').sortable();

            // Initialize Select2 Elements
            $('select:not(.select2-ignore):not(.entityItemInput):not(.ui-datepicker-month):not(.ui-datepicker-year)').select2();

            $('form:not(.ays-ignore)').areYouSure({
                message: 'You have made changes, sure to leave unsaved?',
                fieldEvents: 'change keyup propertychange input ifChecked ifUnchecked',
            });

            $('fieldset.container-wrap .validItem .wysiwyg').each(
                function each() {
                    if (CKEDITOR.instances[$(this).attr('id')]) {
                        delete CKEDITOR.instances[$(this).attr('id')];
                    }
                    createCkeditorInstace($(this));
                }
            );
            $('.wysiwyg').not('fieldset.container-wrap .wysiwyg').each(
                function each() {
                    if (CKEDITOR.instances[$(this).attr('id')]) {
                        CKEDITOR.instances[$(this).attr('id')].destroy();
                        delete CKEDITOR.instances[$(this).attr('id')];
                    }
                    createCkeditorInstace($(this));
                }
            );

            // header for table
            $('.toggle').click(function toggleClick(e) {
                $(`#frm-${$(this).attr('name')}-form`).slideToggle('fast');

                if ($(this).hasClass('ui-icon-minusthick')) {
                    $(this).removeClass('ui-icon-minusthick');
                    $(this).addClass('ui-icon-plusthick');
                } else {
                    $(this).removeClass('ui-icon-plusthick');
                    $(this).addClass('ui-icon-minusthick');
                }

                e.preventDefault();
            });

            // buttons inside EntitiesForm
            $("div.input-group input[type='text']").change(function inputOnChange() {
                if (!$(this).val()) {
                    $(this).closest('div.input-group').find('.input-group-addon.danger').addClass('disn');
                    $(this).closest('div.input-group').find('.input-group-addon.add').addClass('disn');
                } else {
                    $(this).closest('div.input-group').find('.input-group-addon.danger').removeClass('disn');
                }
            });

            // add button inside EntitiesForm
            $("div.input-group input[type='text']").on('keyup', function inputOnKeyUp(e) {
                if (e.key !== 'Enter') {
                    if (!$(this).val()) {
                        $(this).closest('div.input-group').find('.input-group-addon.add').addClass('disn');
                    } else {
                        $(this).closest('div.input-group').find('.input-group-addon.add').removeClass('disn');
                    }
                }
            });
        },
    });

    $.nette.ext('errorToFlash', {
        error(payload) {
            if (payload.status !== 200) {
                if (typeof payload.responseJSON !== 'undefined') {
                    alert(payload.responseJSON.errorMessage, '', payload.responseJSON.errorTitle);
                }
            }
        },
    });

    $.nette.ext({
        load() {
            // remove all open tooltips without parent
            // happens when source of tooltip is replaced (ajax refresh)
            $("body > .tooltip.in").each(function () {
                if ($("[aria-describedby=" + this.id + "]").length === 0) {
                    $(this).remove();
                }
            });
        }
    });

    let alert = function alert(message, elem, title) {
        $(document.createElement('div'))
            .attr({
                class: 'alert',
            })
            .html(message)
            .dialog({
                buttons: {
                    OK() {
                        $(this).dialog('close');
                        $(elem).focus();
                        $(elem).css('background', '#ffdddf');
                    },
                },
                closeOnEscape: true,
                draggable: true,
                modal: true,
                resizable: false,
                width: 'auto',
                title,
                open() {
                    $('.ui-dialog').css('z-index', '10000');
                },
            });
    };

    // fixed sidebar
    if ($.fn.mCustomScrollbar) {
        $('.menu_fixed').mCustomScrollbar({
            autoHideScrollbar: true,
            theme: 'minimal',
            mouseWheel: { preventDefault: true },
            mouseWheelPixels: 200,
            callbacks: {
                onInit: function(){
                    if ($('#sidebar-menu li.active').length !== 0) {
                        $('.menu_fixed').mCustomScrollbar(
                            'scrollTo',
                            $('#sidebar-menu li.active').offset().top + 100,
                            {
                                moveDragger: true,
                                scrollInertia: 0
                            }
                        );
                    }
                }
            },
        });
    }

    $(document).mouseup((e) => {
        let notifications = $('.allNotifications');
        if (!$('.show-notifications').is(e.target)
            && !notifications.is(e.target)
            && notifications.has(e.target).length === 0) {
            notifications.hide('slide', {
                direction: 'left',
            });
        }
    });

    $(document).mousedown(function mouseDown(e) {
        let history = $('img.history');
        if (!history.is(e.target)) {
            $('img.history').not(this).popover('hide');
        }
    });
});

// Panel toolbox
$(document).ready(function documentReady() {
    $('.collapse-link').on('click', function collapseLinkClick() {
        let $BOX_PANEL = $(this).closest('.x_panel');

        let $ICON = $(this).find('i');

        let $BOX_CONTENT = $BOX_PANEL.find('.x_content');

        // fix for some div with hardcoded fix class
        if ($BOX_PANEL.attr('style')) {
            $BOX_CONTENT.slideToggle(200, () => {
                $BOX_PANEL.removeAttr('style');
            });
        } else {
            $BOX_CONTENT.slideToggle(200);
            $BOX_PANEL.css('height', 'auto');
        }

        $ICON.toggleClass('fa-chevron-up fa-chevron-down');
    });

    $('.close-link').click(function closeLinkClick() {
        $(this).closest('.x_panel').remove();
    });

    $('.wysiwyg').each(function each() {
        if (CKEDITOR.instances[$(this).attr('id')]) {
            return;
        }
        createCkeditorInstace($(this));
    });
});
// /Panel toolbox

// Tooltip
$(document).ready(() => {
    functions.initTooltips();
});
// /Tooltip

// initSelect2Class
$(document).ready(() => {
    functions.initSelect2Class();
});
// /initSelect2Class

// Progressbar
if ($('.progress .progress-bar')[0]) {
    $('.progress .progress-bar').progressbar();
}
// /Progressbar

// Switchery
$(document).ready(() => {
    if ($('.js-switch')[0]) {
        let elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
        elems.forEach((html) => {
            new Switchery(html, {
                color: '#26B99A',
            });
        });
    }
});
// /Switchery

// elfinder modal dialog
$(document).on('click', '#dialogElfinderOpen', function click(e) {
    e.preventDefault();
    let input = $(this).prev();
    let img = $(input).prev();
    $('<div id="editor" />').dialogelfinder({
        url: '/backend/js/vendor/elfinder/php/connector.php',
        width: '80%',
        height: '600px',
        getFileCallback: (file) => {
            $(input).val(file.url);
            $(img).attr('src', file.url);
            $('#editor').remove(); // close the window after image is selected
        }
    }).elfinder('instance');
});
// /elfinder modal dialog

// save multiordering
$(document).on('click', '#saveOrder', function saveOrder() {
    let values = {};
    $('div.order-select select').each(function selectOrderEach() {
        values[$(this).attr('id')] = $(this).val();
    });
    $.nette.ajax({
        type: 'POST',
        url: $(this).attr('url'),
        data: values,
    });
});

// Accordion
$(document).ready(() => {
    $('.expand').on('click', function expandClick() {
        $(this).next().slideToggle(200);
        let $expand = $(this).find('>:first-child');

        if ($expand.text() === '+') {
            $expand.text('-');
        } else {
            $expand.text('+');
        }
    });
});

// Bulk action
window.checkState = '';
$(document).ready(() => {
    functions.initCheckboxesHandlers();
});

// NProgress
if (typeof NProgress !== 'undefined') {
    $(document).ready(() => {
        NProgress.done();
    });
}

// tabs
$(document).ready(() => {
    let url = location.href.replace(/\/$/, '');
    let ckeditorInstances = [];
    $('#sortable').sortable();

    if (location.hash) {
        let hash = url.split('#');
        if (hash !== undefined) {
            $('input[name="selectedTab"]').val(hash[1]);
        }
        $(`a[href="#${hash[1]}"]`).tab('show');
        url = location.href.replace(/\/#/, '#');
        history.replaceState(null, null, url);
        setTimeout(() => {
            $(window).scrollTop(0);
        }, 400);
    }

    $('a[data-toggle="tab"]').on('click', function aDataToggleClick() {
        let newUrl;
        let hash = $(this).attr('href');

        if (hash !== undefined) {
            $('input[name="selectedTab"]').val(hash.substring(1));
        }
        if (hash === '#home') {
            newUrl = url.split('#')[0];
        } else {
            newUrl = url.split('#')[0] + hash;
        }
        newUrl += '/';
        history.replaceState(null, null, newUrl);
    });
});
