$(document).ready(function documentReadyAjacUpload(e) {
    // zakomentovano pro pripadne pristi pouziti (upload obrazku ajaxem hned po orezeni)
    /* $("#uploadimage").on('submit',(function(e) {
        e.preventDefault();
        $.ajax({
            url: "ajax_php_file.php", // Url to which the request is send
            type: "POST",             // Type of request to be send, called as method
            data: new FormData(this), // Data sent to server, a set of key/value pairs (i.e. form fields and values)
            contentType: false,       // The content type used when sending data to the server.
            cache: false,             // To unable request pages to be cached
            processData:false,        // To send DOMDocument or non processed data file it is set to false
            success: function(data)   // A function to be called if request succeeds
                {}
        });
    })); */

    // for bug with empty space
    $('#darkroom-icons').css('overflow', 'hidden');

    // Function to preview image after validation
    $(function fceFileChangeDef() {
        $('#file').change(function fileChange() {
            let file = this.files[0];
            let imagefile = file.type;
            let match = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!((imagefile === match[0]) || (imagefile === match[1]) || (imagefile === match[2]))) {
                $('#image_preview').empty();
                alert('Neplatný formát souboru.');
                return false;
            } else {
                let reader = new FileReader();
                reader.onload = imageIsLoaded;
                reader.readAsDataURL(this.files[0]);
            }
        });
    });

    function imageIsLoaded(e)
    {
        // empty div and create empty picture
        let fb = $.fancybox.open('<div id="image_preview_full" class="darkroom-image_preview_full"></div>',
            { touch: false });

        $('#image_preview_full').empty();
        // create img element
        $('#image_preview_full').append('<img id="previewing" src=""/>');
        $('#file').css('color', 'green');
        $('#previewing').attr('src', e.target.result);

        let drk = new Darkroom('#previewing', {
            plugins: {
                crop: {
                    minHeight: 449,
                    minWidth: 449,
                    ratio: 1
                },
                save: {
                    callback: function darkroomCallback() {
                        this.darkroom.selfDestroy();
                        let newImage = drk.canvas.toDataURL();
                        $('#final_preview').attr('src', newImage).addClass('img-margin');
                        $.fancybox.close();
                    }
                }
            }
        });
    }
});
