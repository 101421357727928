let Diacritics = require('diacritic');

$(document).ready(() => {
    $('#frm-addEdit-name').keyup(function keyup() {
        let value = Diacritics.clean(this.value.toLowerCase());
        let nameRegex = /^[a-zA-Z0-9\-\.\ ]+$/;
        if (value.match(nameRegex) == null) return;

        let action = $('#frm-addEdit').attr('action');
        let index = action.indexOf('add');
        if (index !== -1) {
            let array = value.split(' ').map(item => item.trim());
            $('#frm-addEdit-username').val(array.join('.'));
        }
    });
});
