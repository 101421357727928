'use strict';

import './vendor.includes';
import './dropzone';
import './contextmenu';
import './ajax_upload';
// import './elfinder-modal';
import './jquery.are-you-sure';
import './jquery.multisortable';
import './dataTables_langs';
import './live-form-validation';
import './nette.forms';
import './sideDialog';
import './ckeditor.nais';
import './highlightjs';

import 'jquery-bar-rating';
import 'bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import 'spectrum-colorpicker2/dist/spectrum.min';
import 'spectrum-colorpicker2/dist/spectrum.min.css';

import './main';
import './systemModule';
