import '../css/entityItems.style.css';

export function initEntityItems(name, rowsCount, missingTranslate) {
    let controlWrapper = $('#items_' + name);
    let controlLabel = $('#items_' + name + ' label');
    let controlErrors = $('#itemsControlErrors_' + name);
    let entityItemsTable = $('#entityItemsTable_' + name);
    let templateRow = $('#templateRow_' + name);
    let entityItemRows = $('#entityItemsTable_' + name + ' .entityItemRow');
    let rowIndex = rowsCount;

    entityItemsTable.find('input').addClass('form-control');
    templateRow.find(":input").prop('disabled', true);

    if (entityItemRows.length > 0) {
        entityItemRows.find('select').select2();
        entityItemRows.find('input[type="checkbox"]').iCheck({ checkboxClass: 'icheckbox_flat-green' });
        entityItemsTable.show();
    }

    // add new EntityItem row event
    $("#addRow_" + name).click(function () {
        entityItemsTable.show();
        let countRowsCurrent = $('#entityItemsTable_' + name + ' .entityItemRow');
        rowIndex = countRowsCurrent.length;
        let newRow = templateRow.clone();
        newRow.removeAttr("id");
        newRow.removeClass("hidden");
        newRow.addClass("entityItemRow");
        newRow.find(":input").each(function () {
            let input = $(this);
            let id = input.attr("id");
            let newId = id + "_" + rowIndex;
            let newSpanId = newId + "_message";
            let span = newRow.find("#" + id + "_message");
            input.attr("id", newId);
            input.attr("data-lfv-message-id", newSpanId);
            input.attr("name", input.attr('name').replace('#index#', rowIndex));
            input.prop('disabled', false);
            span.attr("id", newSpanId);
        });
        templateRow.before(newRow);
        newRow.find('select').select2();
        newRow.find('input[type="checkbox"]').iCheck({ checkboxClass: 'icheckbox_flat-green' });
        initCalendars();
        rowIndex++;

        if (controlLabel.hasClass('text-danger')) {
            controlLabel.removeClass('text-danger');
            controlErrors.empty();
        }
    });

    // delete EntityItem row event
    $(entityItemsTable).on("click", ".entityItemDelete", function (event) {
        event.preventDefault();
        $(this).closest("tr").remove();

        if ($('#items_' + name + ' .entityItemRow').length === 0) {
            entityItemsTable.hide();
            if (controlWrapper.hasClass('required')) {
                controlLabel.addClass('text-danger');
                controlErrors.append(createErrorMsgElement(missingTranslate));
            }
        }
    });

    // required validation on submit event
    entityItemsTable.parents('form').submit(function () {
        if (controlWrapper.hasClass('required') && $('#items_' + name + ' .entityItemRow').length === 0) {
            if (controlLabel.hasClass('text-danger') === false) {
                controlLabel.addClass('text-danger');
                controlErrors.append(createErrorMsgElement(missingTranslate));
            }
            return false;
        }
    });

    function createErrorMsgElement(errorText) {
        let messageWrapper = document.createElement('div');
        let icon = document.createElement('i');

        icon.classList.add('glyphicon', 'glyphicon-exclamation-sign');
        icon.setAttribute('aria-hidden', 'true');
        messageWrapper.classList.add('text-danger');
        messageWrapper.appendChild(icon);
        messageWrapper.appendChild(document.createTextNode(' ' + errorText));
        return messageWrapper;
    }

}
window.initEntityItems = initEntityItems;
