'use strict';

import 'highlight.js';
import 'highlight.js/styles/default.css';

const hljs = require('highlight.js');

$(document).ready(function documentReady() {
    document.querySelectorAll('.highlightjs').forEach(el => {
        hljs.highlightElement(el);
    });
});

window.hljs = hljs;
