let dataTablesLangs = [];

dataTablesLangs['cs'] = {
    sEmptyTable: 'Tabulka neobsahuje žádná data',
    sInfo: 'Zobrazuji _START_ až _END_ z celkem _TOTAL_ záznamů',
    sInfoEmpty: 'Zobrazuji 0 až 0 z 0 záznamů',
    sInfoFiltered: '(filtrováno z celkem _MAX_ záznamů)',
    sInfoPostFix: '',
    sInfoThousands: ' ',
    sLengthMenu: 'Zobraz záznamů _MENU_',
    sLoadingRecords: 'Načítám...',
    sProcessing: 'Provádím...',
    sSearch: 'Hledat:',
    sZeroRecords: 'Žádné záznamy nebyly nalezeny',
    oPaginate: {
        sFirst: 'První',
        sLast: 'Poslední',
        sNext: 'Další',
        sPrevious: 'Předchozí'
    },
    oAria: {
        sSortAscending: ': aktivujte pro řazení sloupce vzestupně',
        sSortDescending: ': aktivujte pro řazení sloupce sestupně'
    }
};

dataTablesLangs['en'] = {
    sEmptyTable: 'No data available in table',
    sInfo: 'Showing _START_ to _END_ of _TOTAL_ entries',
    sInfoEmpty: 'Showing 0 to 0 of 0 entries',
    sInfoFiltered: '(filtered from _MAX_ total entries)',
    sInfoPostFix: '',
    sInfoThousands: ',',
    sLengthMenu: 'Show _MENU_ entries',
    sLoadingRecords: 'Loading...',
    sProcessing: 'Processing...',
    sSearch: 'Search:',
    sZeroRecords: 'No matching records found',
    oPaginate: {
        sFirst: 'First',
        sLast: 'Last',
        sNext: 'Next',
        sPrevious: 'Previous'
    },
    oAria: {
        sSortAscending: ': activate to sort column ascending',
        sSortDescending: ': activate to sort column descending'
    }
};

window.dataTablesLangs = dataTablesLangs;
