// import $ from 'jquery';
import 'dropzone/dist/min/dropzone.min.css';

const Dropzone = require('dropzone');

Dropzone.autoDiscover = false;
let dropzoneMarked = false;
let dropzoneEventsActive = false;
let newFileIdIndex = 0;
const dropzoneIds = [];

$(document).ready(() => {
    $.nette.ext({
        load() {
            dropzonesInit();
        }
    });
});

function dropzonesInit()
{
    const dropzoneFileInputs = $('div[dropzone=yes] input[type=file]:not(.dropzoneInitialized)');

    dropzoneFileInputs.each((index, fileInput) => {
        fileInput.classList.add('dropzoneInitialized');
        let dropzoneId = fileInput.getAttribute('data-dropzoneId');
        dropzoneIds.push(dropzoneId);
        let initFiles = fileInput.hasAttribute('data-initFiles')
            ? JSON.parse(fileInput.getAttribute('data-initFiles')) : null;
        setupDropzone(fileInput, dropzoneId, initFiles);
    });

    if (dropzoneFileInputs.length > 0) {
        if (!dropzoneEventsActive) {
            dropzoneEventsActive = true;
            activateDropzoneEvents();
        }
    }
}

function setupDropzone(fileInput, dropzoneId, initFiles)
{
    const defaultThumbnail = '/backend/images/file.png';
    const dropzoneContainerId = fileInput.parentElement.getAttribute('id');
    const isMultiple = fileInput.hasAttribute('multiple');
    const messages = JSON.parse(fileInput.getAttribute('data-infoText'));
    const options = JSON.parse(fileInput.getAttribute('data-options'));
    const fileTags = [];

    let dropzoneOptions = {
        paramName: fileInput.getAttribute('id'),
        url: `?do=saveDropzoneFilesToTemp&dropzone=${dropzoneId}`,
        autoProcessQueue: true,
        uploadMultiple: isMultiple,
        previewsContainer: `#${dropzoneContainerId}`,
        addRemoveLinks: 1,
        dictRemoveFile: messages.removeFile,
        dictCancelUpload: messages.cancelUpload,
        dictMaxFilesExceeded: messages.fileCountExceeded,
        dictDefaultMessage: messages.dropzoneInfo,
        dictFileTooBig: messages.dictFileTooBig,
        dictInvalidFileType: messages.dictInvalidFileType,
        parallelUploads: isMultiple ? 100 : 1,
        maxFiles: isMultiple ? 100 : 1,
        maxfilesexceeded(file) {
            this.removeAllFiles(true);
            this.addFile(file);
        },
        init() {
            if (initFiles instanceof Array) {
                initFiles.forEach((initFile) => {
                    fileTags.push(initFile.tag);
                    let file = { name: initFile.name, size: initFile.size, status: Dropzone.SUCCESS };
                    this.files.push(file);
                    this.emit('addedfile', file);

                    let thumbnail = initFile.thumbnail ? initFile.thumbnail : defaultThumbnail;
                    this.emit('thumbnail', file, thumbnail);

                    file.accepted = true;
                    file.previewElement.setAttribute('data-fileTag', initFile.tag);

                    if (initFile.downloadHash) {
                        let fileDetail = $(file.previewElement).find('div.dz-details');
                        fileDetail.css('cursor', 'pointer');
                        fileDetail.click(() => {
                            location.href = `?do=downloadDropzoneFile&hash=${initFile.downloadHash}`;
                        });
                    }

                    this.emit('complete', file);
                });
                $(this.element).siblings('input[type=hidden]').val(fileTags);
            }

            this.on('dragleave', () => {
                this.element.style.borderWidth = '2px';
            });

            this.on('dragenter', (event) => {
                let dataTransfer = event.dataTransfer;

                if (dataTransfer.types && dataTransfer.types.indexOf('Files') !== -1) {
                    this.element.style.borderWidth = '3px';
                }
            });

            this.on('drop', () => {
                resetDropzoneStyle();
            });

            this.on('success', (file, responseText) => {
                let fileTag = file.previewElement.getAttribute('data-fileTag');

                if (responseText instanceof Object
                    && responseText['result'] instanceof Object
                    && responseText.result['duplicity'] instanceof Object
                    && responseText.result.duplicity[fileTag]
                ) {
                    this.removeFile(file);
                    if (!isAlertDialogVisible()) {
                        alert(messages.duplicityError);
                    }
                } else {
                    fileTags.push(fileTag);
                    $(this.element).siblings('input[type=hidden]').val(fileTags);
                }
            });

            this.on('addedfile', (file) => {
                if (file.type.indexOf('image/') < 0) {
                    this.emit('thumbnail', file, defaultThumbnail);
                }
            });

            this.on('removedfile', (file) => {
                let fileTag = file.previewElement.getAttribute('data-fileTag');
                fileTags.splice(fileTags.indexOf(fileTag), 1);
                $(this.element).siblings('input[type=hidden]').val(fileTags);
            });

            this.on('sending', (file, xhr, formData) => {
                let $fileInput = $(file.previewElement).siblings('input[type=file]');
                let fileInputName = $fileInput.attr('id');
                let duplicitySetting = $fileInput.attr('data-duplicityCheck');
                let fileTag = file.previewElement.getAttribute('data-fileTag');

                if (!fileTag) {
                    fileTag = generateNewFileTag();
                    file.previewElement.setAttribute('data-fileTag', fileTag);
                }

                const fileData = {
                    size: String(file.size),
                    filename: file.name,
                    duplicityCheck: duplicitySetting || ''
                };

                formData.append(`fileTag[${fileInputName}][${fileTag}]`, JSON.stringify(fileData));

                let existingFileTags = [];

                for (let i = 0; i < this.files.length; i++) {
                    let tag = this.files[i].previewElement.getAttribute('data-fileTag');
                    if (tag === undefined) {
                        existingFileTags = [];
                        break;
                    }
                    existingFileTags.push(tag);
                }

                formData.append(`existingFileTags[${fileInputName}]`, existingFileTags);
            });
        }
    };
    var properties = Object.getOwnPropertyNames(options);
    properties.forEach(function(propertyName){
        dropzoneOptions[propertyName] = options[propertyName];
    });
    return new Dropzone(`#${dropzoneContainerId}`, dropzoneOptions);
}

function updateDropzoneTempDirLocks() {
    $.nette.ajax({
        type: 'POST',
        url: '?do=updateDropzoneTempDirLocks',
        data: { dropzoneIds }
    });
}

function activateDropzoneEvents() {
    // update temp dir locks every 10 minutes
    setInterval(updateDropzoneTempDirLocks, 10 * 60 * 1000);

    $(document).on('dragenter', (event) => {
        if (dropzoneMarked) {
            return;
        }

        dropzoneMarked = true;
        let dataTransferTypes = event.originalEvent.dataTransfer.types;

        if (dataTransferTypes && dataTransferTypes.indexOf('Files') !== -1) {
            event.preventDefault();
            let colors = ['red', 'green', 'blue', 'black'];
            let $dropzone = $('.dropzone');
            let dropzonesCount = $dropzone.length;

            let left = $('.left_col').innerWidth();
            let outerSpaceWidth = $(window).width() - left;
            let outerSpaceHeight = $(window).height();

            let realTop = 0;
            let realLeft = left;
            let realWidth = dropzonesCount < 2 ? outerSpaceWidth : Math.round(outerSpaceWidth / 2);
            let realHeight = dropzonesCount < 3 ? outerSpaceHeight : Math.round(outerSpaceHeight / 2);

            $dropzone.each((index, element) => {
                if (!$(element).hasClass('drag_upload')) {
                    $(element).addClass('drag_upload');
                }
                element.style.border = `2px dashed ${colors[index]}`;
                element.style.borderRadius = '15px';
                element.style.position = 'fixed';
                element.style.width = `${realWidth}px`;
                element.style.height = `${realHeight}px`;
                element.style.top = `${realTop}px`;
                element.style.left = `${realLeft}px`;
                element.style.marginTop = '0';
                element.style.opacity = '0.8';
                element.style.zIndex = '1001';
                $(element).children('.dz-message').show().css('font-size', '25px');
                $(element).children('.dz-preview').css('display', 'none');

                if (dropzonesCount === 2) {
                    realLeft += realWidth;
                }

                if (dropzonesCount > 2) {
                    // reset for new row
                    if (index === 1) {
                        realWidth = (dropzonesCount === 3) ? realWidth * 2 : realWidth; // third div show bottom for whole view
                        realLeft = left;
                        realTop = realHeight;
                    } else {
                        realLeft += realWidth;
                    }
                }
            });
        }
    });

    // prevent all file drops
    window.addEventListener('dragover', (event) => {
        event.preventDefault();
    }, false);

    window.addEventListener('drop', (event) => {
        event.preventDefault();
        resetDropzoneStyle();
    }, false);

    $(document).on('dragleave', (event) => {
        event.preventDefault();
        if ((event.originalEvent.clientX <= 0 && event.originalEvent.clientY <= 0)
            || (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && event.relatedTarget == null)) {
            resetDropzoneStyle();
        }
    });

    $(document).mouseleave(() => {
        resetDropzoneStyle();
    });
}

function generateNewFileTag()
{
    newFileIdIndex++;
    let random4hex = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    let fileIndex = newFileIdIndex.toString();
    while (fileIndex.length < 4) {
        fileIndex = `0${fileIndex}`;
    }
    return (random4hex() + random4hex() + random4hex() + fileIndex);
}

function resetDropzoneStyle()
{
    $('.dropzone').each((index, element) => {
        element.style = '';

        if ($(element).hasClass('drag_upload')) {
            $(element).removeClass('drag_upload');
        }

        let previewElement = $(element).children('.dz-preview');
        let messageElement = $(element).children('.dz-message');
        previewElement.css('display', 'inline-block');
        messageElement.css('font-size', '13px');

        if (previewElement.length > 0) {
            messageElement.hide();
        } else {
            messageElement.show();
        }
    });
    dropzoneMarked = false;
}

function isAlertDialogVisible()
{
    let isAlertVisible = false;
    $('.ui-dialog').each((index, element) => {
        if (($(element).find('.alert').length > 0) && ($(element).css('display') !== 'none')) {
            isAlertVisible = true;
            return false;
        }
    });
    return isAlertVisible;
}
