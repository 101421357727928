(function ($) {
    $.fn.naisSideDialog = function() {
        let URL_PARAM_NAME = 'naisSideDialog'; // parameter name in URL when history is enabled
        let SNIPPET_ID = 'snippet--nais-side-dialog'; // id of element for nette snippet

        let historyEnabled = false; // enable history modifying (history pushState)

        let events = {};

        // jQuery elements
        let $background;
        let $window;
        let $closeButton;
        let $snippet;

        let dialog = this;

        /**
         * Create side dialog elements and append them to body
         */
        function createElements() {
            // background element
            $background = $('<div>', {
                class: 'nais-sd-background',
            });
            // window element
            $window = $('<div>', {
                class: 'nais-sd-window'
            });
            // close button element
            $closeButton = $('<div>', {
                class: 'nais-sd-close-button fa fa-angle-right fa-2x'
            });
            // snippet element
            $snippet = $('<div>', {
                id: SNIPPET_ID
            });

            // add close button to dialog window
            $closeButton.appendTo($window);

            // insert div.nais-sd-container with snippet inside
            $window.append($('<div>', {
                class: 'nais-sd-container'
            }).html($snippet));

            // add the same close event to close button and dark background
            $closeButton.add($background).on('click', clickToCloseDialog);

            /**
             * Close button and background click handler
             */
            function clickToCloseDialog() {
                closeSideDialog();
                if (historyEnabled) {
                    history.pushState(null, null, '#'); // push null as history state object and empty hash part
                }
            }

            // append elements to body
            $background.appendTo('body');
            $window.appendTo('body');
        }

        /**
         * Open dialog with specific url
         * @param url
         */
        function openSideDialog(url) {
            // if body contains side dialog elements, do not create them again.
            if ($('body').find($window).length === 0) {
                createElements();
            }
            // show semi-transparent background
            $background.fadeIn(200);

            // remove all open tooltips, they can interfere with side dialog
            $("body > .tooltip.in").each(function () {
                $(this).remove();
            });

            trigger('opening');

            // slide in side dialog from right
            $window.show('slide', {
                direction: 'right'
            }, () => {
                // Send AJAX request to provided URL. Cooperation with nette snippets and ajax handling is needed
                // Call $this->sideDialogAjaxHandler(); in presenter's action method
                trigger('open');
                $.nette.ajax({
                    url
                });
            });
        }

        /**
         * Click handler for links
         * @returns {boolean}
         */
        function clickToOpenDialog() {
            let url = $(this).attr('href');
            if ($(this).data('sd-history') === 'on') {
                // enable working with browser's history
                historyEnabled = true;
            }

            // open dialog
            openSideDialog(url);

            if (historyEnabled) {
                // push state object to history and modify url (useful for copy - paste with dialog opened)
                history.pushState(
                    {
                        naisSideDialog: url
                    },
                    null,
                    `#${URL_PARAM_NAME}=${url}`
                );
            }

            // do not go to url
            return false;
        }

        /**
         * Close dialog and empty its contents
         */
        function closeSideDialog() {
            if ($snippet !== undefined) {
                $snippet.html('');
            }
            trigger('closing');
            // slide back
            if ($window !== undefined) {
                $window.hide('slide', {
                    direction: 'right'
                }, () => {
                    trigger('close');
                });
            }
            if ($background !== undefined) {
                $background.fadeOut(200);
            }
        }

        /**
         * history.popState event handler - when user clicks on back or forward button
         * @param e jQuery event
         */
        function loadFromHistoryState(e) {
            let state = e.originalEvent.state; // load pushed state object
            if (state !== null && state.naisSideDialog !== undefined) {
                // if there is state object with naisSideDialog attribute, load that url.
                openSideDialog(state.naisSideDialog);
            } else if (state === null && window.location.hash === null) {
                // no state object? close dialog.
                closeSideDialog();
            }
        }

        /**
         * Public method for open Side dialog
         * @param url
         * @param useHistory
         */
        dialog.open = function (url, useHistory = true) {
            openSideDialog(url);

            if (useHistory) {
                historyEnabled = true;
                // push state object to history and modify url (useful for copy - paste with dialog opened)
                history.pushState(
                    {
                        naisSideDialog: url
                    },
                    null,
                    `#${URL_PARAM_NAME}=${url}`
                );
            }
        }

        /**
         * Public method for close Side dialog
         */
        dialog.close = function () {
            closeSideDialog();
            if (historyEnabled) {
                history.pushState(null, null, '#'); // push null as history state object and empty hash part
            }
        }

        dialog.on = function (name, listener) {
            if (!events[name]) {
                events[name] = [];
            }

            events[name].push(listener);
            return dialog;
        }

        dialog.off = function (name) {
            if (!events[name]) {
                return dialog;
            }
            events[name] = [];
            return dialog;
        }

        function trigger(name, data) {
            if (!events[name]) {
                return;
            }

            const fireCallbacks = (callback) => {
                callback(data);
            };

            events[name].forEach(fireCallbacks);
        }

        // handle history popstate event
        $(window).on('popstate.sideDialog', loadFromHistoryState);
        // handle all .nais-side-dialog links click
        $(document).on('click', '.nais-side-dialog', clickToOpenDialog);

        setTimeout(function () {
            let hash = window.location.hash;

            // if there is hash part in url with side dialog parameter url, open url.
            if (hash.substring(1, URL_PARAM_NAME.length + 1) === URL_PARAM_NAME) { // +1 for "#" character
                let url = hash.substring(URL_PARAM_NAME.length + 2); // +2 for "#" and "=" characters

                // hash part in url means that history editing is enabled
                historyEnabled = true;

                // update actual history state object with provided url
                history.replaceState(
                    {
                        naisSideDialog: url
                    },
                    null
                );

                // open dialog
                openSideDialog(url);
            }
        }, 0);


        return this;
    }
    window.naisSideDialog = $().naisSideDialog();
})(jQuery);